
button{
    all: unset;
  }
  
  .button_container{
    height: 100%;
    width: 100%;

    position: absolute;

    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 50;

    color: black;
  }

  .button_container:hover{
    color: white;
  }

      .button_skeleton{

        position: absolute;
        left: 0;
        top: 0;

        color: white;
      
        border: 2px solid black;
        border-radius: 1vmin;
      
        width: 100%;
        height: 100%;
      
        display: flex;
        justify-content: center;
        align-items: center;

        text-decoration: unset;
    }    

    .button_background{
      margin: 0;
      padding: 0;

      background-color: black;
     
      border-radius: 1vmin;
      position: absolute;
      z-index: -4;

      height: 100%;
      width: 0px;

      left: 0;
      top: 0;
    }