.open_app_button{
    position: fixed;
    top: 5vmin;
    right: 5vmin;

    width: 20%;
    height: 5%;
}


