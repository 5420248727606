.report_page_loading_page_container_outer{
    width:100vw;
    height:100vh;

    display: flex;
    justify-content: center;
    align-items: center;

    flex-direction: column;
}

    .report_page_loading_page_container_inner
    {
        width: 45%;

        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    @media only screen and (min-width: 600px)
    {
        .report_page_loading_page_container{
            width: 25%;
        }
    }

        .report_page_loading{
            width: 0;
            height: auto;
            aspect-ratio: 1/1;

            border: 15px solid black;
            border-bottom-color: white;
            border-radius: 50%;
        }

        /** Code from Stack Overflow **/

        @-webkit-keyframes rotating /* Safari and Chrome */ {
            from {
            -webkit-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            }
            to {
            -webkit-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            transform: rotate(360deg);
            }
        }
        @keyframes rotating {
            from {
            -ms-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            }
            to {
            -ms-transform: rotate(360deg);
            -moz-transform: rotate(360deg);
            -webkit-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            transform: rotate(360deg);
            }
        }
        .rotating {
            -webkit-animation: rotating 2s linear infinite;
            -moz-animation: rotating 2s linear infinite;
            -ms-animation: rotating 2s linear infinite;
            -o-animation: rotating 2s linear infinite;
            animation: rotating 2s linear infinite;
        }

/** Loading is over, this is the non loading page. **/
.report_page_report_container
{
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
}

    .report_page_predicted_price_display{
        width: 70%;

        display: flex;
        flex-direction: column;
        
        align-items: center;
        padding-bottom: 2%;
        border-bottom: 1px solid black;
    }

    .report_page_number_display>*{
        margin: 0;
    }

    .report_page_number_display>h1{
        margin: 0;
        font-size: 15vmin;
    }

    .report_page_number_display{
        display: flex;
        align-items: baseline;
    }

    .report_page_text
    {
        margin: 2%;
        text-align: justify;
    }


    .back_to_inputs_button{
        position: absolute;
        top: 2%;
        left: 2%;

        width: 10%;
        height: 5%;

    }