.intro_container{
    height: 300vh;
}

.content{
    position: fixed;
}

.deep_blue{
    position: absolute;
    top: 25%;
    right:25%;
}

.alpha_go{
    position: absolute;
    top: 25%;
    right: 10%;
}

.j{
    position: absolute;
    top: 20%;
    right: 50%;
}