.inputs_page_container{
    display: flex;
    justify-content: center;
}

    .chess_themed_background_pattern{
        position: absolute;
        
        background-image: url('../../assets/ChessThemedBackground.jpg');

        width: 100vw;
        height: 100vh;

        opacity: 100%;

        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .white_overlay{
        position: absolute;

        background-color: black;
        width: 100vw;
        height: 100vh;

        opacity: 65%;
        mix-blend-mode: hard-light;
    }

    .back_to_landing_button{
        width: 10%;
        height: 4%;
        
        position: absolute;
        
        top: 2%;
        left: 2%;
    }

    .inputs_page_content_container{
        width: 80%;
        height: 80%;

        background-color: white;
        opacity: 100%;

        border-radius: 2vmin;

        position: absolute;

        top: 10%;

        display: flex;
        justify-content: center;
    }

    .question_display{
        position: absolute;
        top: 25%;
        
        height: 5%;
        width: 50%;

        border-radius: 1.5vmin;
        text-align: center;
    }

    .question_navigations{
        position: absolute;
        top: 60%;
        
        display: flex;
        justify-content: space-between;

        width: 50%;
        z-index: 10;
    }

    .begin_button{
        position: absolute;

        top: 60%;

        width: 20%;
        height: 5%;
    }
    