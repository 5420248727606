.main_container{
    width: 100%;
    height: 100%;    
}


.Writing_Container{
    display: flex;
    flex-direction: row;
        justify-content: center;
        align-items: center;
}

.drop_down_input_bar{
    width: 100%;
    height: 100%;

    text-align: center;
    
    border-radius: 2vmin;
}

.list_items_display{
    width: 100%;
    max-height: 45vh;

    background-color: white;

    border: 2px solid black;
    border-radius: 1vmin;

    position:absolute;

    z-index: 99;
    overflow-y: scroll;


}

.individual_items{
    width: 100%;
    cursor: context-menu;
}

.individual_items:hover{
    color: white;
    background-color: black;
}

