/** 
  - Rules of Writing CSS Here 
      - Indent all that descripte the same thing.
                    display: flex;
                      justify-content: flex-end;
                      align-items: center;

  - @Media queries should always be at the end of the page

  - Elements directly accessed without reference shall come before 
  those accessed with Classes

*/

*{
  font-size: calc(7px + 2vmin);
}

.link{
  text-decoration: none;
  text-decoration: unset;
}